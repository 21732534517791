<template>
  <div class="register">
    <div class="jumbotron">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div>
              <h2>Register new User</h2>
              <form @submit.prevent="handleSubmit">
                <div class="form-group">
                  <label for="username">Username</label>
                  <input type="text" v-model="user.username" id="username" name="username" class="form-control" :class="{ 'is-invalid': $v.user.username.$error }" @input="$v.user.username.$touch()" />
                  <div v-if="$v.user.username.$error" class="invalid-feedback">
                    <span v-if="!$v.user.username.required">Username is required</span>
                    <span v-else-if="!$v.user.username.minLength">Username must be at least 6 characters</span>
                    <span v-else-if="!$v.user.username.alphaNum">Username must be alpha numeric and without whitespaces</span>
                    <span v-else-if="!$v.user.username.usernameExists">Username is already registered</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" v-model="user.email" id="email" name="email" class="form-control" :class="{ 'is-invalid': $v.user.email.$error }" @input="$v.user.email.$touch()" />
                  <div v-if="$v.user.email.$error" class="invalid-feedback">
                    <span v-if="!$v.user.email.required">Email is required</span>
                    <span v-else-if="!$v.user.email.email">Email is invalid</span>
                    <span v-else-if="!$v.user.email.emailExists">Email is already registered</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" v-model="user.password" id="password" name="password" class="form-control" :class="{ 'is-invalid': $v.user.password.$error }" @input="$v.user.password.$touch()" />
                  <div v-if="$v.user.password.$error" class="invalid-feedback">
                    <span v-if="!$v.user.password.required">Password is required</span>
                    <span v-else-if="!$v.user.password.minLength">Password must be at least 6 characters</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="confirmPassword">Confirm Password</label>
                  <input type="password" v-model="user.confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': $v.user.confirmPassword.$error }" @input="$v.user.confirmPassword.$touch()" />
                  <div v-if="$v.user.confirmPassword.$error" class="invalid-feedback">
                    <span v-if="!$v.user.confirmPassword.required">Confirm Password is required</span>
                    <span v-else-if="!$v.user.confirmPassword.sameAsPassword">Passwords must match</span>
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary">Register</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <br />
    <h1>Register new User</h1>
    <br />

    <input type="text" v-model="user.username" required="required" placeholder="username" /><br /><br />
    <input type="email" v-model="user.email" required="required" placeholder="email" /><br /><br />
    <input type="password" v-model="user.password" required="required" placeholder="password" /><br /><br />
    <input type="password" v-model="user.confirmPassword" required="required" placeholder="password" /><br /><br />
    <div>
      <span v-bind:class="{ red: passwordStrength.score <= 1, orange: passwordStrength.score == 2, yellow: passwordStrength.score == 3, green: passwordStrength.score == 4 }"></span>
      <span v-bind:class="{ grey: passwordStrength.score <= 1, orange: passwordStrength.score == 2, yellow: passwordStrength.score == 3, green: passwordStrength.score == 4 }"></span>
      <span v-bind:class="{ grey: passwordStrength.score <= 2, yellow: passwordStrength.score == 3, green: passwordStrength.score == 4 }"></span>
      <span v-bind:class="{ grey: passwordStrength.score <= 3, green: passwordStrength.score == 4 }"></span>
    </div>

    <button v-on:click="registerNewUser()">Register User</button>
    <!-- <button @click="retrieveUser()">get User</button> -->

    <!-- <button v-if="showResponse" @click="retrieveUser()">Retrieve user {{ user.id }} data from database</button>
    <h4 v-if="showRetrievedUser">Retrieved User {{ retrievedUser.username }} {{ retrievedUser.email }}</h4>
    <div v-if="showResponse">
      <h6>User registered with Id: {{ response }}</h6>
    </div> -->
  </div>
</template>

<script>
import api from "./backend-api";
import { required, alphaNum, email, minLength, sameAs } from "vuelidate/lib/validators";
import { usernameExists, emailNotExists } from "./validators";
import bcrypt from "bcryptjs";
// Include the library to validate password security
const zxcvbn = require("zxcvbn");
/* var usernameExists = false;
var emailExists = false; */

export default {
  name: "register",

  data() {
    return {
      response: [],
      errors: [],
      submitted: false,
      user: {
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      /* usernameExists: false,
      emailExists: false,*/

      showResponse: false,
      retrievedUser: {},
      showRetrievedUser: false,
    };
  },
  validations: {
    user: {
      username: { required, alphaNum, minLength: minLength(6), usernameExists },
      email: { required, email, emailNotExists },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  watch: {
    /* getUsername() {
      api
        .getUsernameExists(this.user.username)
        .then((response) => {
          usernameExists = response.data;
          console.log("username exists: " + response.data);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getEmail() {
      api
        .getEmailExists(this.user.email)
        .then((response) => {
          emailExists = response.data;
          console.log("email exists: " + response.data);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }, */
    // whenever question changes, this function will run
    /*     user: function(newQuestion, oldQuestion) {
      this.passwordStrength();
    }, */
  },
  computed: {
    /*     getUsername() {
      return this.user.username;
    },
    getEmail() {
      return this.user.email;
    }, */
    passwordStrength() {
      return zxcvbn(this.user.password);
    },
    /*     getUsernameExists() {
      api
        .getUsernameExists(this.user.username)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getEmailExists() {
      api
        .getEmailExists(this.user.email)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }, */
  },
  methods: {
    handleSubmit(e) {
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("form invalid");
        return;
      }
      console.log("form invalid");
      //alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.user));

      var userdata = {};
      userdata.username = this.user.username;
      userdata.email = this.user.email;

      /* const salt = bcrypt.genSaltSync(10);
      userdata.password = bcrypt.hashSync(this.user.password, salt);
      console.log("userdata: " + JSON.stringify(userdata)); */

      userdata.password = this.user.password;

      api
        .registerUser(userdata)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.user.id = response.data;

          if (this.user.id == -1) {
            // ID == -1 -> username exists
          } else if (this.user.id == -1) {
            // ID == -2 -> email exists
          } else {
            console.log("Created new User with Id: " + this.user.id);
            this.submitted = true;
            //redirect to Activation/Welcome Page
            //alert("User successfully registered, login now! TODO: redirect user to Dashboard");
            this.$router.push("/Login");
          }
          this.showResponse = true;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    // Fetches posts when the component is created.
    registerNewUser() {
      if (this.passwordStrength.score < 4) {
        // Weak password, re-do
        alert("weak pass");
      } else {
        // Password is strong!
        alert("strong pass");
      }

      /* var userdata = {};
      userdata.username = this.user.username;
      userdata.email = this.user.email;

      const salt = bcrypt.genSaltSync(10);
      userdata.password = bcrypt.hashSync(this.user.password, salt);
      console.log("userdata: " + JSON.stringify(userdata));

      api
        .registerUser(userdata)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.response = response.data;
          this.user.id = response.data;
          console.log("Created new User with Id " + response.data);
          this.showResponse = true;
        })
        .catch((e) => {
          this.errors.push(e);
        }); */
    },
    retrieveUser() {
      api
        .getUser(this.user.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.retrievedUser = response.data;
          this.showRetrievedUser = true;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 0px;
}

a {
  color: #42b983;
}

.red {
  color: red;
}
.orange {
  color: orange;
}
.yellow {
  color: yellow;
}
.green {
  color: green;
}
</style>
