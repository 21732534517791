<template>
  <div id="app">
    <!-- <div id="nav" class="navbar navbar-dark bg-dark">
      <nav class="navbar navbar-light" style="color: #fff; background-color: #343a40;">
        <router-link to="/" class="nav-item">Hello</router-link>
        <router-link to="/solution" class="nav-item">Solution</router-link>
        <router-link to="/patcher" class="nav-item">Patcher</router-link>
        <router-link to="/callservice" class="nav-item">Service</router-link>
        <router-link to="/bootstrap" class="nav-item">Bootstrap</router-link>
        <router-link to="/user" class="nav-item">User</router-link>
        <router-link to="/login" class="nav-item">Login</router-link>
        <router-link to="/protected" class="nav-item">Protected</router-link>
      </nav>
    </div> -->
    <nav id="nav" class="navbar navbar-expand-md navbar-dark bg-dark">
      <a class="navbar-brand" href="/">
        <img src="./assets/ecu1.png" width="30" height="30" alt="EcuCoder Logo" />
      </a>

      <!-- Collapse button -->
      <button class="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="dark-blue-text">
          <i class="fas fa-bars fa-1x"></i>
        </span>
      </button>

      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto float-left">
          <div class="navbar">
            <li class="nav-link active">
              <router-link to="/" class="nav-item">Home</router-link>
            </li>
            <li class="nav-link">
              <router-link to="/howto" class="nav-item">How to</router-link>
            </li>
            <li class="nav-link">
              <router-link to="/contact" class="nav-item">Contact</router-link>
            </li>
            <li class="nav-link">
              <router-link to="/bootstrap" class="nav-item">Bootstrap</router-link>
            </li>

            <!-- <li class="nav-link">
              <router-link to="/patcher" class="nav-item">Patcher</router-link>
            </li>
            <li class="nav-link ">
              <router-link to="/solution" class="nav-item">Solution</router-link>
            </li>
            <li class="nav-link">
              <router-link to="/callservice" class="nav-item">Service</router-link>
            </li> -->
            <li class="nav-link">
              <router-link to="/register" class="nav-item">Register</router-link>
            </li>
          </div>
        </ul>
        <ul class="navbar-nav float-right">
          <div class="navbar container-fluid float-right">
            <!-- Show login or dashboard and logout by state -->
            <li class="nav-link">
              <router-link v-if="this.$store.getters.isLoggedIn" to="/dashboard" class="nav-item">Dashboard</router-link>
            </li>
            <li class="nav-link">
              <router-link v-if="this.$store.getters.isLoggedIn" to="/protected" class="nav-item">Protected</router-link>
            </li>
            <li class="nav-link">
              <!-- <option v-if="solution.solutionType === type" v-for="solution in availableSolutions" :key="solution.version" :value="solution.version">{{ solution.version }}</option> -->
              <router-link v-if="!this.$store.getters.isLoggedIn" to="/login" class="nav-item">Login</router-link>
              <a href="/" v-else @click="logout">Logout</a>
              <!-- <router-link v-else to="/logout" class="nav-item">Logout</router-link> -->
            </li>
          </div>
        </ul>
        <!-- <form class="form-inline mt-2 mt-md-0">
          <a class="nav-link" href="#" tabindex="-1" aria-disabled="true">Login/Logout</a>
        </form> -->
      </div>
    </nav>

    <div>
      <router-view :hellomsg="msg"></router-view>
    </div>

    <!-- FOOTER -->
    <footer class="footer">
      <p class="float-right padding-right-5"><a href="#">Back to top</a></p>
      <p>&copy; 2022 kytec &middot; <a href="#">Privacy</a> &middot; <a href="#">Terms</a></p>

      <!-- JS Imports -->
      <!-- <script type="application/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/feather-icons/4.9.0/feather.min.js"></script>
      <script type="application/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.3/Chart.min.js"></script> -->
      <!-- <script type="application/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/js/all.min.js"></script> -->
    </footer>

    <vue-cookie-accept-decline
      :ref="'myPanel1'"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >
      <!-- Optional -->
      <div slot="postponeContent">
        &times;
      </div>

      <!-- Optional -->
      <div slot="message">We use cookies to ensure you get the best experience on our website. <a href="https://cookiesandyou.com/" target="_blank">Learn More...</a></div>

      <!-- Optional -->
      <div slot="declineContent">
        OPT OUT
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        GOT IT!
      </div>
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
export default {
  mounted() {
    //this.$serverUrl = "kayore";
    //console.log(this.$store.getters.getServerUrl); // 'Global Scope!'
    this.determinePlatform();
  },

  name: "app",
  data() {
    return {
      status: null,
      msg: "Welcome to your Vue.js powered Spring Boot App",
    };
  },

  //props: ["serverUrl"],
  /* props: {
    serverUrl: {
      type: String,
  	},
  }, */

  beforeUpdate() {
    //alert(this.$serverUrl);
  },

  methods: {
    determinePlatform: function() {
      this.serverUrl = window.location.hostname;
      if (this.serverUrl === "localhost") {
        this.serverPort = 8088;
        //store.commit('increment', 10)
        //this.$store.mutations.setServerUrl = "https://" + this.serverUrl + ":" + this.serverPort;
        var url = "http://" + this.serverUrl + ":" + this.serverPort;
        this.$store.commit("setServerUrl", url);
        console.log("App is running on DevServer!");
      } else {
        //this.$store.mutations.setServerUrl = "https://" + this.serverUrl;
        var url = "https://" + this.serverUrl;
        this.$store.commit("setServerUrl", url);
      }
    },
    logout: function() {},
    /* loginModal: function (event) {
      // `this` inside methods points to the Vue instance
      alert('Hello ' + this.name + '!');

      this.$modal.show(
        Login,
        { text: 'This text is passed as a property' },
        { draggable: true }
      );

      // `event` is the native DOM event
      if (event) {
        alert(event.target.tagName);
      }
    }, */

    cookieStatus(status) {
      console.log("status: " + status);
      this.status = status;
    },
    cookieClickedAccept() {
      console.log("here in accept");
      this.status = "accept";
    },
    cookieClickedDecline() {
      console.log("here in decline");
      this.status = "decline";
    },
    cookieRemovedCookie() {
      console.log("here in cookieRemoved");
      this.status = null;
      this.$refs.myPanel1.init();
    },
    removeCookie() {
      console.log("Cookie removed");
      this.$refs.myPanel1.removeCookie();
    },
  },

  computed: {
    statusText() {
      return this.status || "No cookie set";
    },
  },

  mount() {
    this.show();
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //margin-top: 20px;
  margin-bottom: 20px;
}

.footer {
  // flex-shrink: 0;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  // padding: 20px;

  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #343a40;
  color: white;
  text-align: center;
}

#nav {
  a {
    font-weight: bold;
    color: #fff;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
