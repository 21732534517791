<template>
  <div class="container-fluid dashboard">
    <div class="row">
      <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <div class="sidebar-sticky pt-3">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link" v-bind:class="{ active: activeView === 'dashboard' }" v-on:click="showView('dashboard')" href="#"><home-icon size="1.5x" class="custom-class"></home-icon>Dashboard <span class="sr-only">(current)</span></a>
              <!-- <a class="nav-link" @click="callEcuFiles123()" v-bind:class="{ active: activeView === 'dashboard' }" href="#"><home-icon size="1.5x" class="custom-class"></home-icon>Dashboard <span class="sr-only">(current)</span></a>-->
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"><file-icon size="1.5x" class="custom-class"></file-icon>Orders</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"><shopping-cart-icon size="1.5x" class="custom-class"></shopping-cart-icon>Products</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"><users-icon size="1.5x" class="custom-class"></users-icon>Support</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" v-bind:class="{ active: activeView === 'patcher' }" v-on:click="showView('patcher')" href="#"><bar-chart-2-icon size="1.5x" class="custom-class"></bar-chart-2-icon>Patcher</a>
              <!-- <router-link to="/patcher" class="nav-item">Patcher</router-link> -->
            </li>
            <li class="nav-item">
              <a class="nav-link" v-bind:class="{ active: activeView === 'solution' }" v-on:click="showView('solution')" href="#"><layers-icon size="1.5x" class="custom-class"></layers-icon>Solution</a>
              <!-- <router-link to="/solution" class="nav-item">Solution</router-link> -->
            </li>
            <li class="nav-item">
              <!-- <router-link class="nav-link" href="#" v-on:click="showView('patcher')"><layers-icon size="1.5x" class="custom-class"></layers-icon>Patcher</router-link> -->
              <!-- <a class="nav-link" href="#" v-on:click="showView('patcher')"><layers-icon size="1.5x" class="custom-class"></layers-icon>Patcher</a> -->

              <!-- <li class="nav-item" v-on:click="showView('solution')" v-class="{ active: isActive }" @click="itemActive($route.path)"> -->
              <!-- <a class="nav-link anyThings" href="#" to="/solution" :class="[itemA == '/solution' ? 'active' : '']"><layers-icon size="1.5x" class="custom-class"></layers-icon>Service</a> -->
            </li>
          </ul>

          <!-- <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Saved reports</span>
            <a class="d-flex align-items-center text-muted" href="#" aria-label="Add a new report"><plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon></a>
          </h6>
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <a class="nav-link" href="#"><file-text-icon size="1.5x" class="custom-class"></file-text-icon>Current month</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"><file-text-icon size="1.5x" class="custom-class"></file-text-icon>Last quarter</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"> <file-text-icon size="1.5x" class="custom-class"></file-text-icon>Social engagement</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"><file-text-icon size="1.5x" class="custom-class"></file-text-icon>Year-end sale</a>
            </li>
          </ul> -->
        </div>
      </nav>

      <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
        <Dashboard v-if="dashboardView"></Dashboard>
        <Solution v-if="solutionView"></Solution>
        <Patcher v-if="patcherView"></Patcher>
      </main>
    </div>
  </div>
</template>

<script>
//import feather from "feather-icons";
//import chartjs from "chart.js";
//import * as feather from 'feather-icons';
//console.log(feather);
import api from "./backend-api";
import { HomeIcon, FileIcon, ShoppingCartIcon, UsersIcon, BarChart2Icon, LayersIcon, PlusCircleIcon, FileTextIcon } from "vue-feather-icons";
import Dashboard from "@/components/Dashboard";
import Patcher from "@/components/Patcher";
import Solution from "@/components/Solution";

export default {
  components: {
    HomeIcon,
    FileIcon,
    ShoppingCartIcon,
    UsersIcon,
    BarChart2Icon,
    LayersIcon,
    PlusCircleIcon,
    FileTextIcon,

    //Views
    Dashboard,
    Solution,
    Patcher,
  },
  mounted() {
    this.showView("dashboard");
    //("use strict");
    //feather.replace();
    /* // Graphs
    var ctx = document.getElementById("myChart");
    // eslint-disable-next-line no-unused-vars
    var myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        datasets: [
          {
            data: [15339, 21345, 18483, 24003, 23489, 24092, 12034],
            lineTension: 0,
            backgroundColor: "transparent",
            borderColor: "#007bff",
            borderWidth: 4,
            pointBackgroundColor: "#007bff",
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    }); */
  },
  data() {
    return {
      name: "login",
      recentSolutions: "",
      loginError: false,
      user: "",
      password: "",
      error: false,
      errors: [],
      isActive: false,
      solutionView: false,
      patcherView: false,
      activeView: "",
    };
  },
  methods: {
    /* callLogin() {
      this.errors = [];
      this.$store
        .dispatch("login", { user: this.user, password: this.password })
        .then(() => {
          this.$router.push("/Protected");
        })
        .catch((error) => {
          this.loginError = true;
          this.errors.push(error);
          this.error = true;
        });
    }, */
    showView(view) {
      this.deactivateViews();
      console.log("LOGME: " + view);
      switch (view) {
        case this.activeView:
          break;
        case "dashboard":
          //this.solutionView = !this.solutionView;
          this.dashboardView = true;
          this.activeView = "dashboard";
          break;
        case "solution":
          //this.solutionView = !this.solutionView;
          this.solutionView = true;
          this.activeView = "solution";
          break;
        case "patcher":
          this.patcherView = true;
          this.activeView = "patcher";
          break;
      }
    },
    deactivateViews() {
      this.dashboardView = false;
      this.solutionView = false;
      this.patcherView = false;
    },

    /* itemActive(e) {
      this.itemA = e;
    }, */
  },
};
</script>

<style scoped>
body {
  font-size: 0.875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/* .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; /* Behind the navbar */
/* padding: 48px 0 0; /* Height of navbar */
/* box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
} */

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.nav.flex-column {
  align-items: baseline;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
</style>
