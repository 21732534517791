import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import $ from "jquery";
import JQuery from "jquery";

import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.css";

//animate css
import 'animate.css/animate.css';

import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

//import VModal from 'vue-js-modal'
import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";

//Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

//feather-icons
//import VueFeatherIcon from 'vue-feather-icon'

//chart.js
import chartjs from "chart.js";

// Bootstrap
Vue.use(BootstrapVue);

// Popper
Vue.use(Popper);

// VModal
Vue.use(VModal);

//vue-feather-icons
//Vue.use(VueFeatherIcon)

//vue-cookie-accept-decline
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

Vue.config.devtools = true;
Vue.config.productionTip = false;
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
//window.$ = window.jQuery = require('jquery');
//window.JQuery = require('jquery')

var app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
