<template>
  <div class="register">
    <div class="jumbotron">
      <div class="row" v-if="!resetPassword">
        <div class="col-md-8 offset-md-2">
          <div>
            <h2>{{ message }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "./backend-api";

export default {
  name: "activateAccount",

  data() {
    return {
      response: [],
      errors: [],
      user: {
        password: "",
        confirmPassword: "",
      },
      showResponse: false,
      token: "",
      tokenIsValid: false,
      message: "",
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    this.checkTokenIsValid();
  },
  methods: {
    checkTokenIsValid() {
      api
        .checkActivationTokenIsValid(this.token)
        .then((response) => {
          // JSON responses are automatically parsed.
            this.message = response.data;

            // Add Spinner and delay of a few seconds before redirecting
            this.$router.push("/Login");
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 0px;
}

a {
  color: #42b983;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.yellow {
  color: yellow;
}

.green {
  color: green;
}
</style>
