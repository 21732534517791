<template>
  <div class="patcher">
    <loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
    <div class="container">
      <div class="row">
        <div class="col">
          <oriFileUpload ref="oriFileUpload" id="oriUpload" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" @vdropzone-success="vsuccess" @vdropzone-removed-file="vremoved" @vdropzone-file-added="vfileAdded">
            <div class="dropzone-custom-content">
              <h5 class="dropzone-custom-title">ECU Ori file! <i class="fas fa-file-upload"></i></h5>
            </div>
          </oriFileUpload>
        </div>
        <div class="col">
          <div class="container">
            <div class="row">Make: {{ ecuInfo.make }}</div>
            <div class="row">Software Id: {{ ecuInfo.swid }}</div>
            <div class="row">Hardware type: {{ ecuInfo.hwtype }}</div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <span>Available solutions: {{ noSolutionsMessage }} {{ availableSolutions }} </span>
    <br />
    <span>Available solutionTypes: {{ availableSolutionTypes }}</span>
    <br />
    <br />

    <div id="solutionAvailable">
      <!-- <div id="solutionAvailable" v-bind:class="[oriUploaded ? 'fadeIn' : 'fadeOut']"> -->
      <div class="row" v-for="(solutionType, index) in availableSolutionTypes">
        <div class="col">
          <div class="row" :id="solutionType + 'Row'">
            <div class="container">
              <div class="row">
                <div class="col-sm">
                  <label :for="solutionType">
                    <input type="checkbox" class="" id="solutionType" :value="solutionType" v-model="checkedSolutions" />
                    {{ solutionType }} Off
                  </label>
                </div>
                <div class="col-sm">
                  <select :ref="solutionType + 'Version'" @change="solutionVersionSelected($event, solutionType)">
                    <option v-for="solution in availableSolutions[solutionType]"> {{ solution.version }}</option>
                  </select>
                </div>
                <div class="col-sm">
                  <span :ref="solutionType + 'Description'"></span>
                  <!-- <span :ref="solutionType + 'Description'"> Notes: {{ getDescriptionForSolutionTypeAndVersion(solutionType, index) }} </span> -->
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
        <!-- <div class="col" v-show="isAvailable('DPF')">
          <label for="dpf"><input type="checkbox" class="" id="dpf" value="DPF" v-model="checkedSolutions" v-on:click="hideDpf = !hideDpf"/> DPF Off</label><br>
        </div>
        <div class="col" v-show="isAvailable('FLAPS')">
          <label for="flaps"><input type="checkbox" class="" id="flaps" value="Flaps" v-model="checkedSolutions" v-on:click="hideFlaps = !hideFlaps"/> FLAPS Off</label><br>
        </div>
        <div class="col"  v-show="isAvailable('Immo')">
          <label for="immo"><input type="checkbox" class="" id="immo" value="Immo" v-model="checkedSolutions" v-on:click="hideImmo = !hideImmo"/> Immo Off</label><br>
        </div> -->
      </div>
    </div>

    <br />
    <span>Checked solutions: {{ checkedSolutions }}</span>
    <br />
    <br />

    <div id="buttonContainer" class="container">
      <div class="row">
        <div class="col-sm">
          <button type="button" class="w-100 btn btn-primary btn-sm" v-on:click="resetSolutions">Reset</button>
        </div>
        <div class="col-sm">
          <button type="button" class="w-100 btn btn-secondary btn-sm" v-on:click="patchEcuFile">Patch ECU File</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "./backend-api";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import vue2Dropzone from "vue2-dropzone";
import "../styles/vue2dropzone.css";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mounted() {
    console.log("serverUrl: " + this.$store.getters.getServerUrl);
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
  },

  components: {
    oriFileUpload: vue2Dropzone,
    Loading,
  },

  data: function() {
    return {
      isLoading: false,
      fullPage: true,

      availableSolutions: [],
      availableSolutionTypes: [],
      checkedSolutions: [],
      noSolutionsMessage: "No solutions available yet, for this Software ID.",
      fileName: "",
      ecuInfo: [],
      errors: [],
      selectedVersion: [],
      // solutionsDetailsMapping:[],
      // hideEgr: true,
      // hideDpf: true,
      // hideLambda: true,
      // hideVmax: true,
      // hideFlaps: true,
      // hideImmo: true,
      oriUploaded: false,
      ecuFileDropzoneOptions: {
        id: "oriUpload",
        //url: window.location.origin + "/uploadFile",
        //url: "http://localhost:8088/uploadFile",
        //url: "http://" + window.location.hostname + ":8088/uploadFile",
        //url: "https://" + window.location.hostname + "/uploadFile",
        url: this.$store.getters.getServerUrl + "/uploadFile",
        duplicateCheck: true,
        maxFilesize: 10,
        maxFiles: 1,
        //headers: { "Host": "localhost:8088", "Origin": "http://localhost:8088" , "Referer": "http://localhost:8088/patcher" },
        init: function() {
          this.on("addedfile", function(event) {
            while (this.files.length > this.options.maxFiles) {
              this.removeFile(this.files[0]);
            }
          });
        },
      },
    };
  },

  // watch: {
  //   oriUploaded: function () {
  //     console.log('RECOGNIZED Event');
  //     this.setDescriptions();
  //   }
  // },

  updated: function() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been re-rendered
      this.setDescriptions();
    });
  },

  // computed: {
  //   // a computed getter
  //   reversedMessage: function () {
  //     // `this` points to the vm instance
  //     return this.message.split('').reverse().join('')
  //   }
  // },

  methods: {
    resetSolutions: function() {
      this.fileName = "";
      this.ecuInfo = [];
      this.availableSolutions = [];
      this.oriUploaded = false;
      this.$refs.oriFileUpload.removeAllFiles();
    },

    patchEcuFile: function() {
      if (this.checkedSolutions.length > 0) {
        console.log("Requesting patches for: " + this.checkedSolutions);
        console.log("Requesting patches for as JSON: " + JSON.stringify(this.checkedSolutions));

        var solutions = {};
        for (let index = 0; index < this.checkedSolutions.length; index++) {
          var solutionType = this.checkedSolutions[index];
          var selection = this.$refs[solutionType + "Version"][0].value;
          selection--;
          var description = this.availableSolutions[solutionType][selection].description;
          var version = this.availableSolutions[solutionType][selection].version;
          var solution = { description: description, version: version };
          // var solution = { "version": version };
          solutions[solutionType] = solution;
        }

        console.log("Sending request for: " + JSON.stringify(solutions));

        // api.callGetModFile(this.fileName, JSON.stringify(this.checkedSolutions))
        this.isLoading = true;
        api
          .callGetModFile(this.fileName, JSON.stringify(solutions))
          // url: 'http://localhost:8000/api/get-file',
          // method: 'GET',
          // responseType: 'blob',
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            var fileName = response.headers["content-disposition"].split("filename=")[1];
            fileName = fileName.substring(1, fileName.length - 1);
            console.log("headers: " + response.headers);
            console.log("filename: " + fileName);
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            console.log("FileInformationError" + error.response);
            this.isLoading = false;
          });
        this.isLoading = false;
      } else {
        alert("Please select a solution to create a mod file.");
      }
    },

    vfileAdded(file) {
      this.isLoading = true;
      console.log("File added...");
    },

    vsuccess(file, response) {
      this.isLoading = true;
      this.oriUploaded = true; //for showing upload fields?
      this.fileName = file.name;
      //this.$refs.solutionUploads.dropzone.files.length;
      console.log("log to console vsuccess file: " + file.name);
      api
        .callEcuFileInformation(file.name)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.ecuInfo = response.data;
          // console.log(this.ecuInfo);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("FileInformationError" + error.response);
          this.isLoading = false;
        });
      this.isLoading = true;
      api
        .callSolutionsEcuFile(file.name)
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log("Solutions available: " + JSON.stringify(response.data));
          if (response.data != "No solutions available yet, for this Software ID.") {
            this.availableSolutions = response.data;
            this.availableSolutionTypes = Object.keys(response.data);
            //} else {
            //this.availableSolutions = [];
            //this.availableSolutionTypes = [];
          }
          console.log("AvailableSolutions: " + JSON.stringify(this.availableSolutions));
          console.log("AvailableSolutionTypes: " + JSON.stringify(this.availableSolutionTypes));
        })
        .catch((error) => {
          console.log("SolutionsError: " + error);
          this.isLoading = false;
        });
      this.isLoading = false;
    },

    vremoved(file, xhr, error) {
      this.oriUploaded = false;
      this.ecuInfo = [];
      // console.log("log to console var file removed: " + file.name);
    },

    setDescriptions() {
      this.availableSolutionTypes.forEach((solutionType) => {
        var selectedVersion = this.$refs[solutionType + "Version"][0].value;
        this.$refs[solutionType + "Description"][0].innerText = "Notes: " + this.availableSolutions[solutionType][selectedVersion - 1].description;
      });
    },

    solutionVersionSelected(event, solutionType) {
      var selection = event.target.value;
      var description = this.availableSolutions[solutionType][selection - 1].description;
      this.$refs[solutionType + "Description"][0].innerText = "Notes: " + description;
      console.log("Value of " + solutionType + "Description: " + description);
      console.log("selection: " + selection);
    },

    // isAvailable(solution){
    //   return this.availableSolutionTypes.includes(solution);
    // },

    // getVersionsForSolutionType(solutionType){
    //   var solutionVersions = [];
    //   for (let index = 0; index < this.availableSolutions.length; index++) {
    //     var solution = this.availableSolutions[index];
    //     if (solution.solutionType === solutionType) {
    //       // var solution = { solutionType  }
    //       versions.push(solution.version);
    //     }
    //   };
    //   console.log("Versions for " + solutionType + ": " + versions);
    //   return versions;
    // },

    // createSolutionDetailsMapping(){
    //   var solutionVersions = [];
    //   for (let index = 0; index < this.availableSolutions[solutionType].length; index++) {
    //     var version = this.availableSolutions[solutionType][index].version;
    //     // if (solution.solutionType === solutionType) {
    //     //   // var solution = { solutionType  }
    //     // }
    //     versions.push(solution.version);
    //   };
    //   console.log("Versions for " + solutionType + ": " + versions);
    //   this.solutionsDetailsMapping = "";
    // },

    // getDescriptionsForSolutionType(solutionType){
    //   var descriptions = [];
    //   for (let index = 0; index < this.availableSolutions.length; index++) {
    //     var solution = this.availableSolutions[index];
    //     if (solution.solutionType === solutionType) {
    //       descriptions.push(solution.description);
    //     }
    //   };
    //   console.log("Descriptions for " + solutionType + ": " + descriptions);
    //   return descriptions;
    // },

    // getDescriptionForSolutionTypeAndVersion(solutionType, version){
    //   var descriptions = [];
    //   for (let index = 0; index < this.availableSolutions.length; index++) {
    //     var solution = this.availableSolutions[index];
    //     if (solution.solutionType === solutionType) {
    //       descriptions.push(solution.description);
    //     }
    //   };
    //   console.log("Descriptions for " + solutionType + ": " + descriptions);
    //   return descriptions[version-1];
    // },

    // getDescriptionForSolutionTypeAndVersion(solutionType, version) {
    //   if (this.availableSolutions.length !== 0) {
    //     return this.availableSolutions[solutionType][version].description;
    //   }
    //   return "";
    // },

    // selChange(index, solutionType){
    //  for(let i = 0; i < this.availableSolutions.length; i++){
    //    if(this.availableSolutions[i].version === this.verDesc[index].ver){
    //        this.verDesc[index].desc = this.availableSolutions[i].description;
    //        return;
    //    }
    //  }
    // }

    // getSelectedVersion(solutionType){
    //   return this.$refs['EGRVersion'].value;
    // }

    // showAvailableSolutions() {
    //   console.log("showing checkboxes for solutions");
    //   for (var solution of this.availableSolutions)
    //   {
    //     switch(solution) {
    //       case "egr":
    //         this.hideEgr = !this.hideEgr;
    //         break;
    //       case "dpf":
    //         this.hideDpf = !this.hideDpf;
    //         break;
    //       case "flaps":
    //         this.hideFlaps = !this.hideFlaps;
    //         break;
    //     }
    //     // document.write(solution + "<br>");
    //   }
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.patcher {
  margin-top: 50px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 0px;
}

a {
  color: #42b983;
}

/* .dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
} */

.dropzone-custom-title {
  margin-top: 0px;
  color: #00b782;
}

.fadeOut {
  display: none;
  opacity: 0;
  transition: opacity 800ms, height 0 800ms;
}

.fadeIn {
  opacity: 1;
  display: block;
}
</style>
