<template>
  <div class="register">
    <div class="jumbotron">
      <div class="container">
        <div class="row" v-if="resetPassword">
          <div class="col-md-8 offset-md-2">
            <div>
              <h2>Reset Password</h2>
              <form @submit.prevent="handleSubmit">
                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" v-model="user.password" id="password" name="password" class="form-control" :class="{ 'is-invalid': $v.user.password.$error }" @input="$v.user.password.$touch()" />
                  <div v-if="$v.user.password.$error" class="invalid-feedback">
                    <span v-if="!$v.user.password.required">Password is required</span>
                    <span v-else-if="!$v.user.password.minLength">Password must be at least 6 characters</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="confirmPassword">Confirm Password</label>
                  <input type="password" v-model="user.confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': $v.user.confirmPassword.$error }" @input="$v.user.confirmPassword.$touch()" />
                  <div v-if="$v.user.confirmPassword.$error" class="invalid-feedback">
                    <span v-if="!$v.user.confirmPassword.required">Confirm Password is required</span>
                    <span v-else-if="!$v.user.confirmPassword.sameAsPassword">Passwords must match</span>
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary" :class="{ active: !$v.$error, disabled: $v.$error }">Change password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="!resetPassword">
        <div class="col-md-8 offset-md-2">
          <div>
            <h2>{{ errorMessage }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "./backend-api";
import { required, alphaNum, email, minLength, sameAs } from "vuelidate/lib/validators";
import { usernameExists, emailNotExists } from "./validators";
import bcrypt from "bcryptjs";
// Include the library to validate password security
const zxcvbn = require("zxcvbn");

export default {
  name: "passwordReset",

  data() {
    // get URL Params
    console.log(this.$route.query.token);

    return {
      response: [],
      errors: [],
      user: {
        password: "",
        confirmPassword: "",
      },
      showResponse: false,
      token: "",
      tokenIsValid: false,
      resetPassword: false,
      errorMessage: "",
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    this.checkTokenIsValid();
  },
  validations: {
    user: {
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  computed: {
    passwordStrength() {
      return zxcvbn(this.user.password);
    },
  },
  methods: {
    checkTokenIsValid() {
      api
        .checkPasswordTokenIsValid(this.token)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.tokenIsValid = response.data;
          if (this.tokenIsValid.hasOwnProperty("error")) {
            this.resetPassword = false;
            this.errorMessage = response.data.error;
            //alert("TokenIsInvalid: " + this.tokenIsValid.error);
          } else if (this.tokenIsValid.hasOwnProperty("token")) {
            this.resetPassword = true;
            this.token = response.data.token;
            //alert("TokenIsValid: " + this.tokenIsValid.token);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    handleSubmit(e) {
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("form invalid");
        return;
      }
      console.log("form invalid");
      //alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.user));

      var user = { "token": this.token, "password": this.user.password };

      api
        .changePassword(user)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.tokenIsValid = response.data;
          if (this.tokenIsValid.hasOwnProperty("error")) {
            this.resetPassword = false;
            this.errorMessage = response.data.error;
            //alert("TokenIsInvalid: " + this.tokenIsValid.error);
          } else if (this.tokenIsValid.hasOwnProperty("token")) {
            this.resetPassword = true;
            this.token = response.data.token;
            //alert("TokenIsValid: " + this.tokenIsValid.token);
          }

          //redirect to Login page
          this.$router.push("/Login");
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 0px;
}

a {
  color: #42b983;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.yellow {
  color: yellow;
}

.green {
  color: green;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
