import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home";
import Howto from "@/components/Howto";
import Solution from "@/components/Solution";
import Patcher from "@/components/Patcher";
import Service from "@/components/Service";
import Bootstrap from "@/components/Bootstrap";
import DashboardMenu from "@/components/DashboardMenu";
import Dashboard from "@/components/Dashboard";
import Login from "@/components/Login";
import Protected from "@/components/Protected";
import Register from "@/components/Register";
import PasswordReset from "@/components/PasswordReset";
import ActivateAccount from "@/components/ActivateAccount";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history", // uris without hashes #, see https://router.vuejs.org/guide/essentials/history-mode.html#html5-history-mode
  routes: [
    // public pages
    { path: "/", component: Home },
    { path: "/howto", component: Howto },
    { path: "/bootstrap", component: Bootstrap },
    { path: "/login", component: Login },
    { path: "/register", component: Register },
    { path: "/password", component: PasswordReset },
    { path: "/activate", component: ActivateAccount },

    // protected pages
    { path: "/dashboard", component: DashboardMenu, meta: { requiresAuth: true } },
    { path: "/callservice", component: Service, meta: { requiresAuth: true } },
    { path: "/solution", component: Solution, meta: { requiresAuth: true } },
    { path: "/patcher", component: Patcher, meta: { requiresAuth: true } },
    { path: "/protected", component: Protected, meta: { requiresAuth: true } },

    // otherwise redirect to home
    { path: "*", redirect: "/" },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in - if not, redirect to login page.
    const loggedIn = store.getters.isLoggedIn;

    if (!loggedIn) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
