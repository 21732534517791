import axios from "axios";

const AXIOS = axios.create({
  baseURL: `/api`,
  timeout: 15000,
});

export default {
  home() {
    return AXIOS.get(`/home`);
  },

  // ###### Mod Files Info ###### //
  callEcuFiles() {
    return AXIOS.get(`/ecuFiles/`);
    //return AXIOS.get(`/ecuFiles/` , { transformResponse: undefined });
    //return AXIOS.post(`/ecuFileInformation/` + fileName, patcher, { transformResponse: undefined });
    // return AXIOS.get(`/ecuFileInformation/` + fileName);
  },

  // ###### Mod File ###### //
  callEcuFileInformation(fileName) {
    // return AXIOS.get(`/ecuFileInformation/` + fileName, { transformResponse: [(data) => { return data; }] });
    return AXIOS.get(`/ecuFileInformation/` + fileName, { transformResponse: undefined });

    //return AXIOS.post(`/ecuFileInformation/` + fileName, patcher, { transformResponse: undefined });
    // return AXIOS.get(`/ecuFileInformation/` + fileName);
  },
  callSolutionsEcuFile(fileName) {
    return AXIOS.get(`/solutions/` + fileName, { transformResponse: undefined });
  },
  callGetModFile(fileName, checkedSolutions) {
    // return AXIOS.get(`/getModFile/` + fileName, { transformResponse: undefined, responseType: 'blob' });
    // return AXIOS.post(`/getModFile/` + fileName, checkedSolutions, {
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     transformResponse: undefined
    // });

    return AXIOS.post(`/getModFile/` + fileName, checkedSolutions, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });
    // return AXIOS.get(`/getModFile/` + fileName, checkedSolutions);
  },
  callEcuModImporter(uploadedSolutions) {
    // return AXIOS.get(`/ecuFileInformation/` + fileName, { transformResponse: [(data) => { return data; }] });
    // return AXIOS.get(`/ecuModImporter/`, { transformResponse: undefined });
    // return AXIOS.post(`/ecuModImporter/`, JSON.stringify(uploadedSolutions));
    return AXIOS.post(`/ecuModImporter/`, uploadedSolutions);
    // return AXIOS.get(`/ecuFileInformation/` + fileName);
  },
  callDeleteSolution(solution) {
    return AXIOS.post(`/deleteSolution/`, solution);
  },
  // ###### Mod File END ###### //

  getUser(userId) {
    return AXIOS.get(`/user/` + userId);
  },
  getUsernameExists(username) {
    return AXIOS.get(`/user/exists/username/` + username);
  },
  getEmailExists(email) {
    return AXIOS.get(`/user/exists/email/` + email);
  },
  //registerUser(username, email, password) {
  registerUser(userdata) {
    return AXIOS.post(`/user/registerUser/`, userdata);
  },
  checkActivationTokenIsValid(token) {
    return AXIOS.get(`/user/activation/token/` + token);
  },
  loginUser(user, password) {
    return AXIOS.get(`/user/login/`, {
      auth: {
        username: user,
        password: password,
      },
    });
  },
  sendActivationMail(email) {
    return AXIOS.get(`/user/activation/` + email);
  },
  sendPasswordMail(email) {
    return AXIOS.get(`/user/password/` + email);
  },
  checkPasswordTokenIsValid(token) {
    return AXIOS.get(`/user/password/token/` + token);
  },
  changePassword(user) {
    return AXIOS.post(`/user/password/change/`, user);
  },
};
