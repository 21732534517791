import api from "./backend-api";

//const noWhitespace = (value) => value.indexOf(' ') === -1;

export const usernameExists = (value) => {
  return api
    .getUsernameExists(value)
    .then((response) => {
      var exists = Boolean(response.data);
      console.log("Response for Username: " + value + ", exists: " + exists);
      return !exists;
    })
    .catch((e) => {
      this.errors.push(e);
    });
};

export const emailExists = (value) => {
  return api
    .getEmailExists(value)
    .then((response) => {
      var exists = Boolean(response.data);
      console.log("Response for Email: " + value + ", exists: " + exists);
      return exists;
    })
    .catch((e) => {
      this.errors.push(e);
    });
};

export const emailNotExists = (value) => {
  return api
    .getEmailExists(value)
    .then((response) => {
      var exists = Boolean(response.data);
      console.log("Response for Email: " + value + ", not exists: " + exists);
      return !exists;
    })
    .catch((e) => {
      this.errors.push(e);
    });
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
