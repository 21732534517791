<template>
  <div class="files">
    <br />
    <br />
    <div class="container">
      <div class="row">
        <!-- <label> {{ recentSolutions }}<br /></label> -->

        <table class="table table-hover table-dark">
          <thead>
            <tr>
              <th scope="col">Software Id</th>
              <th scope="col">Hardware Id</th>
              <th scope="col">ECU Make</th>
              <th scope="col">ECU Type</th>
              <th scope="col">Description</th>
              <th scope="col">Solution Type</th>
              <th scope="col">Version</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="solution in recentSolutions">
              <td>{{ solution.solutionId }}</td>
              <td>{{ solution.hwid }}</td>
              <td>BOSCH</td>
              <td>EDC</td>
              <td>{{ solution.description }}</td>
              <td>{{ solution.type }}</td>
              <td>{{ solution.version }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from "./backend-api";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import vue2Dropzone from "vue2-dropzone";
import "../styles/vue2dropzone.css";
import $ from "jquery";

export default {
  mounted() {
    console.log("serverUrl: " + this.$store.getters.getServerUrl);
    this.getEcuFiles();
  },

  components: {
  },

  data: function() {
    return {
      viewStateKey: 0,
      recentSolutions: [],
      availableSolutions: [],
      availableSolutionTypes: [],
    };
  },

  methods: {
    getEcuFiles() {
      api
        .callEcuFiles()
        .then((response) => {
          this.recentSolutions = response.data;
          //this.recentSolutions = JSON.stringify(response.data);
          //console.log("callEcuFiles: " + JSON.parse(JSON.stringify(response.data)));
          console.log("Recent Files available: " + JSON.stringify(response.data));
          console.log("Recent Files available raw: " + JSON.stringify(response));
          
          //var obj = JSON.parse('{ "name":"John", "age":30, "city":"New York"}'); 
          //console.log("recentSolutions[1]: " + obj.name);
          //console.log("recentSolutions[1]: " + recentSolutions[0].id);
          //this.recentSolutions = response.data;
          //this.recentSolutions = Object.keys(response.data);
          console.log("AvailableSolutions: " + JSON.stringify(this.recentSolutions));
          console.log("AvailableSolutionTypes: " + JSON.stringify(this.availableSolutionTypes));
        })
        .catch((error) => {
          this.errors.push(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.solution {
  margin-top: 50px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 0px;
}

a {
  color: #42b983;
}

/* .dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
} */

.dropzone-custom-title {
  margin-top: 0px;
  color: #00b782;
}

.fadeOut {
  display: none;
  opacity: 0;
  transition: opacity 800ms, height 0 800ms;
}

.fadeIn {
  opacity: 1;
  display: block;
}
</style>
