<template>
  <!-- <div class="solution" :key="componentKey"> -->
  <div class="solution">
    <loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading>
    <div class="container">
      <div class="row">
        <div class="col">
          <oriFileUpload ref="oriFileUpload" id="oriUpload" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" @vdropzone-success="vsuccess" @vdropzone-removed-file="vremoved" @vdropzone-sending="vsending" @vdropzone-file-added="vfileAdded">
            <!-- <oriFileUpload ref="oriFileUpload" id="oriUpload" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" @vdropzone-success="vsuccess" @vdropzone-removed-file="vremoved" @vdropzone-file-added="vfileadded" @vdropzone-sending="vsending"> -->
            <div class="dropzone-custom-content">
              <h5 class="dropzone-custom-title">ECU Ori file! <i class="fas fa-file-upload"></i></h5>
            </div>
          </oriFileUpload>
        </div>
        <div class="col">
          <div class="container">
            <div class="row">Make: {{ ecuInfo.make }}</div>
            <div class="row">Software Id: {{ ecuInfo.swid }}</div>
            <div class="row">Hardware type: {{ ecuInfo.hwtype }}</div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <span>Available solutions: {{ availableSolutions }}</span>
    <br />
    <br />

    <div id="solutionAvailable" v-bind:class="[oriUploaded ? 'fadeIn' : 'fadeOut']">
      <div class="row">
        <!-- <fieldset v-if="places" class="checklist">
          <h4>Select your store locations:</h4>
          <div v-for="solution in availableSolutions">
            <input :id="solution" type="checkbox" v-model="place.checked">
            <label :for="solution">
              {{ solution }}<br>
            </label>
          </div>
        </fieldset> -->

        <!-- <div class="col" v-show="isAvailable('egr')">
          <label for="egr"> <input type="checkbox" class="" id="egr" value="EGR" v-model="checkedSolutions" v-on:click="hideEgr = !hideEgr"/> EGR Off</label><br>
        </div>
        <div class="col" v-show="isAvailable('dpf')">
          <label for="dpf"><input type="checkbox" class="" id="dpf" value="DPF" v-model="checkedSolutions" v-on:click="hideDpf = !hideDpf"/> DPF Off</label><br>
        </div>
        <div class="col" v-show="isAvailable('flaps')">
          <label for="flaps"><input type="checkbox" class="" id="flaps" value="FLAPS" v-model="checkedSolutions" v-on:click="hideFlaps = !hideFlaps"/> FLAPS Off</label><br>
        </div>
        <div class="col"  v-show="isAvailable('immo')">
          <label for="immo"><input type="checkbox" class="" id="immo" value="IMMO" v-model="checkedSolutions" v-on:click="hideImmo = !hideImmo"/> Immo Off</label><br>
        </div> -->
      </div>
    </div>

    <br />
    <span>Checked solutions: {{ checkedSolutions }}</span>
    <br />
    <br />

    <div id="solutionUploads" v-bind:class="[oriUploaded ? 'fadeIn' : 'fadeOut']">
      <!-- <div v-for="type in solutionTypes" class="row" :id="type + 'Row'"> -->
      <div class="row" v-for="type in solutionTypes" :id="type + 'Row'">
        <div class="col">
          <!-- <div class="container-fluid" :v-if="'hide' + type"> -->
          <div class="container-fluid">
            <div class="row">
              <!-- <div class="col-sm"> -->
              <!-- <label :for="type"><input type="checkbox" class="" :id="type" :value="type" v-model="checkedSolutions" :v-on:click="hideEgr = !hideEgr"/> {{ type }} Off</label><br> -->
              <!-- <label :for="type"><input type="checkbox" class="" :id="type" :value="type" v-model="checkedSolutions"/> {{ type }} Off</label> -->
              <!-- </div> -->
              <div class="col-sm">
                <label :for="type">
                  <input type="checkbox" class="" :id="type" :value="type" v-model="checkedSolutions" />
                  {{ type }} Off
                </label>
              </div>
              <div class="col-sm">
                <div class="input-group input-group-sm sm-3">
                  <!-- <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelectVersion">Version</label>
                    </div> -->
                  <select class="custom-select" :ref="type + 'Version'" :id="type + 'Version'" :v-model="type + 'Version'" @change="solutionVersionSelected($event, type)">
                    <option value="0" selected>Add new version</option>
                    <option v-for="solution in availableSolutions[type]"> {{ solution.version }}</option>
                    <!-- <option v-if="solution.solutionType === type" v-for="solution in availableSolutions" :key="solution.version" :value="solution.version">{{ solution.version }}</option> -->
                  </select>
                </div>
                <!-- <select v-model="selected">
                    <option v-if="solution.solutionType === type" v-for="solution in availableSolutions" :key="solution.version" :value="solution.version">{{ solution.version }}</option>
                    <option :value="null" selected>Add new version</option>
                  </select> -->
                <!-- <b-form-select :v:-model="type + 'Version'" class="mb-3">
                    <b-form-select-option :value="null">Add new version</b-form-select-option>
                    <b-form-select-option v-if="solution.solutionType === type" v-for="solution in availableSolutions" :key="solution.version" :value="solution.version" size="sm" class="">{{ solution.version }}</b-form-select-option>
                  </b-form-select> -->
                <!-- <div class="mt-3">Selected: <strong>{{ egrVersion }}</strong></div> -->
              </div>
              <div class="col-sm">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">Description</span>
                  </div>
                  <!-- <input :key="type" :v-model="type + 'Description'" :ref="type + 'Description'" :id="type + 'Description'" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"> -->
                  <input class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" :ref="type + 'Description'" :id="type + 'Description'" />
                </div>
              </div>
              <div class="col-sm">
                <button type="button" class="btn btn-dark" :v-bind:disabled="getSelectedVersion(type) !== 0" v-on:click="deleteSolution(type)">Delete Solution</button>
              </div>
              <div class="col-sm">
                <!-- <fileUpload class="w-50" :ref="type + 'SolutionUpload'" id="type + 'Upload'" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" v-if="hideEgr" v-on:vdropzone-processing="(file) => processingEvent(file, type)"> -->
                <fileUpload class="w-50" :ref="type + 'SolutionUpload'" :id="type + 'Upload'" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" v-on:vdropzone-processing="(file) => processingEvent(file, type)">
                  <!-- <egrFileUpload class="w-50" ref="egrSolutionUpload" id="egrUpload" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" v-if="hideEgr" v-on:vdropzone-processing="(file) => processingEvent(file, 'egr')" v-on:vdropzone-sending="(file) => sendingEvent(file, 'egr')"> -->
                  <div class="dropzone-custom-content">
                    <h5 class="dropzone-custom-title">Mod file! <i class="fas fa-file-upload"></i></h5>
                  </div>
                </fileUpload>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row">
          <div class="col">
            <label for="dpf"><input type="checkbox" class="" id="dpf" value="DPF" v-model="checkedSolutions" v-on:click="hideDpf = !hideDpf"/> DPF Off</label><br>
          </div>
          <div class="col">
            <dpfFileUpload class="w-50" ref="dpfSolutionUpload" id="dpfUpload" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" v-if="hideDpf" v-on:vdropzone-processing="(file) => processingEvent(file, 'dpf')">
                <div class="dropzone-custom-content">
                    <h5 class="dropzone-custom-title">DPF Mod file! <i class="fas fa-file-upload"></i></h5>
                </div>
            </dpfFileUpload>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label for="lambda"><input type="checkbox" class="" id="lambda" value="Lambda" v-model="checkedSolutions" v-on:click="hideLambda = !hideLambda"/> Lambda Removal</label><br>
          </div>
          <div class="col">
            <lambdaFileUpload class="w-50" ref="lambdaSolutionUpload" id="lambdaUpload" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" v-if="hideLambda" v-on:vdropzone-processing="(file) => processingEvent(file, 'lambda')">
                <div class="dropzone-custom-content">
                    <h5 class="dropzone-custom-title">Lambda Mod file! <i class="fas fa-file-upload"></i></h5>
                </div>
            </lambdaFileUpload>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="vmax"><input type="checkbox" class="" id="vmax" value="VMAX" v-model="checkedSolutions" v-on:click="hideVmax = !hideVmax"/> VMAX Removal</label><br>
          </div>          
          <div class="col">
            <vmaxFileUpload class="w-50" ref="vmaxSolutionUpload" id="vmaxUpload" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" v-if="hideVmax" v-on:vdropzone-processing="(file) => processingEvent(file, 'vmax')">
                <div class="dropzone-custom-content">
                    <h5 class="dropzone-custom-title">VMAX Mod file! <i class="fas fa-file-upload"></i></h5>
                </div>
            </vmaxFileUpload>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="flaps"><input type="checkbox" class="" id="flaps" value="Flaps" v-model="checkedSolutions" v-on:click="hideFlaps = !hideFlaps"/> Swirl Flaps Off</label><br>
          </div>
          <div class="col">
            <flapsFileUpload class="w-50" ref="flapsSolutionUpload" id="flapsUpload" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" v-if="hideFlaps" v-on:vdropzone-processing="(file) => processingEvent(file, 'flaps')">
                <div class="dropzone-custom-content">
                    <h5 class="dropzone-custom-title">Flaps Mod file! <i class="fas fa-file-upload"></i></h5>
                </div>
            </flapsFileUpload>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="immo"><input type="checkbox" class="" id="immo" value="Immo" v-model="checkedSolutions" v-on:click="hideImmo = !hideImmo"/> Immo Off</label><br>
          </div>
          <div class="col">
            <immoFileUpload class="w-50" ref="immoSolutionUpload" id="immoUpload" :options="ecuFileDropzoneOptions" :useCustomSlot="true" :include-styling="false" v-if="hideImmo" v-on:vdropzone-processing="(file) => processingEvent(file, 'immo')">
                <div class="dropzone-custom-content">
                    <h5 class="dropzone-custom-title">Immo Mod file! <i class="fas fa-file-upload"></i></h5>
                </div>
            </immoFileUpload>
          </div>
        </div> -->
    </div>

    <div id="buttonContainer" class="container">
      <div class="row">
        <div class="col-sm">
          <button type="button" class="w-100 btn btn-primary btn-sm" v-on:click="resetSolutions">Reset</button>
        </div>
        <div class="col-sm">
          <button type="button" class="w-100 btn btn-secondary btn-sm" v-on:click="importSolutions">Import Solutions</button>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <!-- <div class="modal-mask" @click="close" v-show="show">
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modal title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ errorMessage }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="overwriteSolution">Save changes</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>
    </div> -->

    <div>
      <!-- <b-button v-b-modal.modal-footer-sm>Small Footer Buttons</b-button> -->

      <b-modal ref="fileExistsModal" id="modal-footer-sm" title="BootstrapVue" button-size="sm">
        <p class="my-2">Solutions already exist for this file, please confirm if solutions should be overwritten.</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import api from "./backend-api";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import vue2Dropzone from "vue2-dropzone";
import "../styles/vue2dropzone.css";
import $ from "jquery";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  // mounted(){

  //   // $(document).ready(function(){
  //   //   $(".row").click(function(){
  //   //     $(this).fadeOut();
  //   //   });
  //   // });

  //   // $('#exampleModal').on('hidden.bs.modal', function (e) {
  //   //   console.log("modalwindow");
  //   // });
  // },
  mounted() {
    console.log("serverUrl: " + this.$store.getters.getServerUrl);
  },

  components: {
    fileUpload: vue2Dropzone,
    oriFileUpload: vue2Dropzone,
    Loading,
    // egrFileUpload: vue2Dropzone,
    // dpfFileUpload: vue2Dropzone,
    // lambdaFileUpload: vue2Dropzone,
    // vmaxFileUpload: vue2Dropzone,
    // flapsFileUpload: vue2Dropzone,
    // immoFileUpload: vue2Dropzone
  },

  data: function() {
    return {
      isLoading: false,
      fullPage: true,

      viewStateKey: 0,
      solutionTypes: ["EGR", "DPF", "Flaps", "Vmax", "Immo"],
      availableSolutions: [],
      availableSolutionTypes: [],
      checkedSolutions: [],
      uploadedSolutions: {},
      ecuInfo: [],
      errors: [],
      // EGRVersion: "",
      // DPFVersion: "",
      // FlapsVersion: "",
      // VmaxVersion: "",
      // ImmoVersion: "",
      // errorMessage: null,
      // hideEgr: false,
      // hideDpf: false,
      // hideLambda: false,
      // hideVmax: false,
      // hideFlaps: false,
      // hideImmo: false,
      oriUploaded: false,
      description: "",
      // EGRVersion: null,
      // EGRDescription : "",
      // selected2: null,
      ecuFileDropzoneOptions: {
        id: "oriUpload",
        //url: window.location.origin + ":8088/uploadFile",
        //url: window.location.host + "/uploadFile",
        //url: "http://localhost:8088/uploadFile",
        //url: "http://" + window.location.hostname + ":8088/uploadFile",
        //url: "https://" + window.location.hostname + "/uploadFile",
        url: this.$store.getters.getServerUrl + "/uploadFile",
        //addRemoveLinks: true,
        duplicateCheck: true,
        maxFilesize: 20,
        maxFiles: 1,
        //headers: { "Host": "localhost:8088", "Origin": "http://localhost:8088" , "Referer": "http://localhost:8088/patcher" },
        init: function() {
          this.on("addedfile", function(event) {
            while (this.files.length > this.options.maxFiles) {
              this.removeFile(this.files[0]);
            }
          });
        },
        //acceptedFiles: ".bin"
        // params: {
        //   "solutionType": "",
        // }
      },
      // modFileDropzoneOptions: {
      //   id: "modUpload",
      //   url: "http://localhost:8088/modFile",
      //   duplicateCheck: true,
      //   maxFilesize: 10,
      //   params: {
      //     "solutionType": "",
      //   }
      //   //maxFiles: 1
      // }
    };
  },

  methods: {
    close: function() {
      this.$emit("close");
    },

    resetSolutions: function() {
      // this.viewStateKey += 1;
      this.ecuInfo = [];
      this.availableSolutions = [];
      this.checkedSolutions = [];
      this.uploadedSolutions = {};
      this.importStatus = false;
      this.oriUploaded = false;
      this.$refs.oriFileUpload.removeAllFiles();
      for (let index = 0; index < this.availableSolutionTypes.length; index++) {
        var element = this.availableSolutionTypes[index];
        this.$refs[element + "Upload"][0].removeAllFiles();
        this.$refs[element + "Description"][0].value = "";
      }
      // this.hideEgr = this.hideEgr == true ? false : this.hideEgr;
      // this.hideDpf = this.hideDpf == true ? false : this.hideDpf;
      // this.hideLambda = this.hideLambda == true ? false : this.hideLambda;
      // this.hideVmax = this.hideVmax == true ? false : this.hideVmax;
      // this.hideFlaps = this.hideFlaps == true ? false : this.hideFlaps;
      // this.hideImmo = this.hideImmo == true ? false : this.hideImmo;
      //this.$refs.solutionUploadsoriFileUpload.removeAllFiles();
    },

    importSolutions: function() {
      // alert("import solutions for file "+ this.ecuInfo + ": " + this.checkedSolutions);
      // var solutionsCount = this.checkedSolutions.length;
      // for (var solutionIndex = 0; solutionIndex < solutionsCount; solutionIndex++) {
      //     // console.log(this.checkedSolutions[solutionIndex]);
      //     //Do solution upload
      //     var solutionModId = this.checkedSolutions[solutionIndex].toLowerCase() +"SolutionUpload";
      //     // console.log("Upload element id: " + solutionModId);
      //     var modUploader = document.getElementById(solutionModId);
      //     var modUploader = this.$refs[solutionModId];
      //     console.log(modUploader);
      // }

      // console.log("TESTVAL: "+ EGRDescription.value);
      // console.log("TESTVAL: "+ ["EGRDescription"].value);

      if (this.checkedSolutions.length > 0) {
        // for (let index = 0; index < this.solutionTypes.length; index++) {
        for (let index = 0; index < this.checkedSolutions.length; index++) {
          var solutionType = this.checkedSolutions[index];

          if (this.uploadedSolutions.hasOwnProperty(solutionType)) {
            // add new solution to database
            var solution = this.uploadedSolutions[solutionType];
            solution.description = $("#" + solutionType + "Description").val();
            // solution.description = this.$refs[solutionType + "Description"][0].innerText;
            // solution.description = this.$refs[solutionType + 'Description'][0].valuee;
            console.log("description from obj: " + solution.description);
            // solution.version = $("#" + solutionType + "Version").value;
            //solution.version = this.$refs[solutionType + "Version"].value == undefined ? 0 : this.$refs[solutionType + "Version"].value;
            //solution.version = this.$refs[solutionType + "Version"][0].value == undefined ? 0 : this.$refs[solutionType + "Version"][0].value;
            // solution.version = parseInt(this.$refs[solutionType + "Version"][0].value);
            solution.version = this.$refs[solutionType + "Version"][0].value;
            this.uploadedSolutions[solutionType] = solution;
            console.log("Solution enriched" + JSON.stringify(solution));
          } else {
            // modify existing solution
            // solution.description = $("#" + solutionType + "Description").value;
            var solution = { description: null, version: null };
            solution.description = $("#" + solutionType + "Description").val();
            // solution.description = this.$refs[solutionType + 'Description'][0].valuee;
            console.log("description from obj: " + solution.description);
            // solution.version = $("#" + solutionType + "Version").value;
            //solution.version = this.$refs[solutionType + "Version"].value == undefined ? 0 : this.$refs[solutionType + "Version"].value;
            //solution.version = this.$refs[solutionType + "Version"][0].value == undefined ? 0 : this.$refs[solutionType + "Version"][0].value;
            // solution.version = parseInt(this.$refs[solutionType + "Version"][0]);

            solution.version = this.$refs[solutionType + "Version"][0].value;
            this.uploadedSolutions[solutionType] = solution;
            console.log("Solution enriched: " + JSON.stringify(solution));
          }
        }

        // console.log(this.solutionTypes);
        // this.solutionTypes.forEach(element => {
        // });

        // console.log(this.solutionTypes);
        // for (var solutionType in this.solutionTypes) {
        //   console.log(solutionType);
        //   /* if (object.hasOwnProperty(key)) {
        //     const element = object[key];
        //   } */
        // }
        /* this.solutionTypes.forEach(element => {
          Object.entries(element).forEach(([key, value]) => {
              console.log(`${key} ${value}`);
          });
          var solution = this.uploadedSolutions[solutionType];
          solution.description = this.$refs[solutionType + "Description"];
          solution.version = this.$refs[solutionType + "Version"];
        }); */
        console.log("enriched solutions " + this.uploadedSolutions);
        // this.uploadedSolutions["EGR"].description =  this.$refs['EGRDescription'];
        // this.uploadedSolutions["EGR"].version =  23;

        this.isLoading = true;
        api
          .callEcuModImporter(this.uploadedSolutions)
          .then((response) => {
            // JSON responses are automatically parsed.
            this.importStatus = response.data;
            //this.ecuInfo = response.data;
            // console.log(this.ecuInfo);
            if (this.importStatus.successful) {
              alert("Successfully imported/modified solutions.");
            } else {
              alert("Error: Solutions were not imported, see log for details.");
              Object.keys(this.importStatus).forEach(function(k) {
                console.log(k + " - " + this.importStatus[k]);
              });
            }
            this.resetSolutions();
          })
          .catch((error) => {
            console.log(error.response);
            this.isLoading = false;
          });
        this.isLoading = false;
      } else {
        alert("No solution selected to import/modify.");
      }
    },

    showModal() {
      this.$refs.fileExistsModal.show();
    },
    hideModal() {
      this.$refs.fileExistsModal.hide();
    },

    // overwriteSolution() {
    //     //do something and overwrite existing solution
    //     this.$refs['exampleModal'].hide()
    // },

    // sendingEvent (file, type, formData) {
    //   this.modFileDropzoneOptions.params = { "solutionType": type };
    //   console.log(type);
    //   // formData.append('solutionType', 'some value or other');
    // },

    processingEvent(file, type) {
      //this.ecuFileDropzoneOptions.params = { "solutionType": type };
      //var solution = { "solutionType": type, "file": file.name, "description": this.egrDescription, "version": this.egrVersion };
      var solution = { file: file.name, description: "", version: 0 };
      this.uploadedSolutions[type] = solution;
      var myJsonString = JSON.stringify(this.uploadedSolutions);
      /* console.log(type + " version: " + this.EGRVersion) */
      /* console.log(type + " description: " + this.egrDescription); */
      /* console.log(type + " description: " + this.EGRDescription); */
      console.log(this.uploadedSolutions);
      console.log(myJsonString);
    },

    say: function(message) {
      var id = message.toLowerCase() + "Upload";
      document.getElementById(id);
      alert(message.toLowerCase());
    },

    deleteSolution: function(type) {
      //get selection from select-input
      var selection = type + "Version";
      var version = this.$refs[selection][0].value;
      alert("Type: " + type + " selectionValue: " + version);

      var solution = { swid: this.ecuInfo.swid, solutionType: type, version: version };
      //call rest api delete function
      this.isLoading = true;
      api
        .callDeleteSolution(solution)
        .then((response) => {
          var result = response.data;
          // JSON responses are automatically parsed.
          console.log("Solution deleted: " + result.msg);
          alert("Solution deleted: " + result.msg);
        })
        .catch((error) => {
          console.log("DeleteSolutionError: " + error);
          this.isLoading = false;
        });
      this.isLoading = false;
    },

    solutionVersionSelected(event, solutionType) {
      var selection = event.target.value;
      var description = "";
      if (selection !== "0") {
        description = this.availableSolutions[solutionType][selection - 1].description;
      }
      this.$refs[solutionType + "Description"][0].value = description;
      console.log("Value of " + solutionType + "Description: " + description);
      console.log("selection: " + selection);
    },

    getSelectedVersion(solutionType) {
      //return this.$refs[solutionType + 'Version'][0].value;
      //return this.$refs['EGRVersion'][0].value;
    },

    /* vfileadded(file){
      console.log("should reset");
    }, */

    vsending(file, xhr, formData) {
      this.resetSolutions();
      console.log("Reset form");
    },

    vfileAdded(file) {
      this.isLoading = true;
      console.log("File added...");
    },

    vsuccess(file, response) {
      this.uploadedSolutions["ori"] = file.name;
      this.oriUploaded = true; //for showing upload fields?
      //this.$refs.solutionUploads.dropzone.files.length;
      //$('#exampleModal').modal('show');
      if (this.availableSolutions.length > 0) {
        this.showModal();
      }
      console.log("log to console vsuccess file: " + file.name);
      api
        .callEcuFileInformation(file.name)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.ecuInfo = response.data;
          // console.log(this.ecuInfo);
        })
        .catch((error) => {
          console.log("FileInformationError" + error.response);
        });
      api
        .callSolutionsEcuFile(file.name)
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log("Solutions available: " + JSON.stringify(response.data));
          this.availableSolutions = response.data;
          this.availableSolutionTypes = Object.keys(response.data);
          console.log("AvailableSolutions: " + JSON.stringify(this.availableSolutions));
          console.log("AvailableSolutionTypes: " + JSON.stringify(this.availableSolutionTypes));
        })
        .catch((error) => {
          console.log("SolutionsError: " + error);
          this.isLoading = false;
        });
      this.isLoading = false;
    },

    vremoved(file, xhr, error) {
      this.oriUploaded = false;
      this.ecuInfo = [];
      console.log("log to console var file removed: " + file.name);
    },

    isAvailable(solution) {
      return this.availableSolutions.includes(solution);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.solution {
  margin-top: 50px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 0px;
}

a {
  color: #42b983;
}

/* .dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
} */

.dropzone-custom-title {
  margin-top: 0px;
  color: #00b782;
}

.fadeOut {
  display: none;
  opacity: 0;
  transition: opacity 800ms, height 0 800ms;
}

.fadeIn {
  opacity: 1;
  display: block;
}
</style>
