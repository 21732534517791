<template>
  <div class="login">
    <!--     <div id="example-3">
      <button v-on:click="show = !show">Toggle render</button>
      <p v-if="show" class="animate__animated animate__fadeInDown" enter-active-class="animated tada animate__fadeInDown" leave-active-class="animated bounceOutRight animate__fadeOutDown">hello</p>
    </div> -->

    <div class="unprotected" v-bind:class="getLoginErrorAnimationClass()" v-if="loginError">
      <form>
        <h1><b-badge variant="danger">You don't have rights here, mate :D</b-badge></h1>
        <i class="fa fa-exclamation-triangle fa-10x" aria-hidden="true"></i>
        <h5>Seems that you don't have access rights...</h5>
        <p v-if="error" class="error">Bad login information</p>
        <button v-on:click="loginError = !loginError">Try again</button>
      </form>
    </div>
    <div class="unprotected" v-bind:class="!getLoginErrorAnimationClass()" v-if="!loginError && !forgotPassword && !resendActivation">
      <!-- <h1><b-badge variant="info">Please login to get access!</b-badge></h1> -->
      <!-- <h5>You're not logged in - so you don't see much here. Try to log in:</h5> -->

      <form class="form-signin" v-on:submit.prevent="callLogin()">
        <i class="fa fa-lock fa-10x" aria-hidden="true"></i>
        <!-- <img class="mb-4" src="../assets/brand/bootstrap-solid.svg" alt="" width="72" height="72"> -->
        <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
        <label for="inputUser" class="sr-only">Username/Email address</label>
        <!-- <input type="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus v-model="user"> -->
        <input type="text" id="inputUser" class="form-control" placeholder="Username or email" required autofocus v-model="credentials.user" />
        <label for="inputPassword" class="sr-only">Password</label>
        <input type="password" id="inputPassword" class="form-control" placeholder="Password" required v-model="credentials.password" />
        <div>
          <label><input type="checkbox" value="remember-me" /> Remember me</label><br />
          <a v-on:click="forgotPassword = !forgotPassword"><chevron-left-icon size="1.0x" class="custom-class"></chevron-left-icon>Forgot password?</a>
          <a v-on:click="resendActivation = !resendActivation"><chevron-left-icon size="1.0x" class="custom-class"></chevron-left-icon>Resend activation?</a>
          <br />
        </div>
        <b-btn variant="success" type="submit">Login</b-btn>
        <!-- <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button> -->
      </form>

      <!-- <form @submit.prevent="callLogin()">
        <input type="text" placeholder="username" v-model="user">
        <input type="password" placeholder="password" v-model="password">
        <b-btn variant="success" type="submit">Login</b-btn>
        <p v-if="error" class="error">Bad login information</p>
      </form> -->
    </div>

    <div class="unprotected" v-if="forgotPassword">
      <div class="col-md-4 form-signin">
        <i class="fa fa-unlock-alt fa-10x"></i>
        <h2 class="text-center">Forgot Password?</h2>
        <p>You can reset your password here.</p>
        <form @submit.prevent="forgotPassword">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
              <input v-model="user.email" id="email" name="email" placeholder="email address" class="form-control" type="email" :class="{ 'is-invalid': $v.user.email.$error }" @input="$v.user.email.$touch()" />
              <!--               <div v-if="$v.user.email.$error" class="invalid-feedback">
                <span v-if="!$v.user.email.required">Email is required</span>
                <span v-else-if="!$v.user.email.email">Email is invalid</span>
                <span v-else-if="!$v.user.email.emailExists">Email is already registered</span>
              </div> -->
            </div>
          </div>
          <div class="form-group">
            <input v-on:click="sendPasswordMail()" name="recover-submit" class="btn btn-lg btn-primary btn-block" :class="{ active: !$v.user.email.$error, disabled: $v.user.email.$error }" value="Reset Password" type="submit" />
            <a v-on:click="forgotPassword = !forgotPassword"><chevron-left-icon size="1.0x" class="custom-class"></chevron-left-icon>go back</a>
          </div>
          <input type="hidden" class="hide" name="token" id="token" value="" />
        </form>
      </div>
    </div>

    <div class="unprotected" v-if="resendActivation">
      <div class="col-md-4 form-signin">
        <i class="fa fa-unlock-alt fa-10x"></i>
        <h2 class="text-center">Resend activation email?</h2>
        <p>You can resend an activation email here.</p>
        <form @submit.prevent="resendActivation">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
              <input v-model="user.email" id="email" name="email" placeholder="email address" class="form-control" type="email" :class="{ 'is-invalid': $v.user.email.$error }" @input="$v.user.email.$touch()" />
              <!--               <div v-if="$v.user.email.$error" class="invalid-feedback">
                <span v-if="!$v.user.email.required">Email is required</span>
                <span v-else-if="!$v.user.email.email">Email is invalid</span>
                <span v-else-if="!$v.user.email.emailExists">Email is already registered</span>
              </div> -->
            </div>
          </div>
          <div class="form-group">
            <input v-on:click="sendActivationMail()" name="recover-submit" class="btn btn-lg btn-primary btn-block" :class="{ active: !$v.user.email.$error, disabled: $v.user.email.$error }" value="Resend Activation" type="submit" />
            <a v-on:click="resendActivation = !resendActivation"><chevron-left-icon size="1.0x" class="custom-class"></chevron-left-icon>go back</a>
          </div>
          <input type="hidden" class="hide" name="token" id="token" value="" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "./backend-api";
import { ChevronLeftIcon } from "vue-feather-icons";
import { required, alphaNum, email, minLength, sameAs } from "vuelidate/lib/validators";
import { emailExists } from "./validators";
//import bcrypt from "bcryptjs";
//var argon = require('argon2');
//const argon2 = require('argon2');
//import argon2 from "argon2";
//var argon2 = require("argon2themax");

export default {
  name: "login",

  data() {
    return {
      show: true,
      timerCount: 5,
      loginError: false,
      forgotPassword: false,
      resendActivation: false,
      loginErrorTransitionCSS: "",
      credentials: {
        user: "",
        password: "",
      },
      user: {
        email: "",
      },
      error: false,
      errors: [],
    };
  },
  validations: {
    user: {
      email: { required, email, emailExists },
    },
  },
  components: {
    ChevronLeftIcon,
  },
  computed: {
    showForgotDialog() {},
  },
  watch: {
    loginError: function(val) {
      getLoginErrorAnimationClass(val);
    },
  },

  methods: {
    getLoginErrorAnimationClass() {
      //var css = "animate__animated animate__fadeInDown";
      var css = "";
      if (!this.loginError) {
        css = "animate__animated animate__fadeOutDown";
      } else {
        css = "animate__animated animate__fadeInDown";
      }
      return css;
    },

    toggleForgotPassword() {
      this.forgotPassword = !this.forgotPassword;
    },

    sendPasswordMail() {
      api
        .sendPasswordMail(this.user.email)
        .then((response) => {
          //redirect to login mask
          this.forgotPassword = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    sendActivationMail(){
      api
        .sendActivationMail(this.user.email)
        .then((response) => {
          //redirect to login mask
          this.resendActivation = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    callLogin() {
      this.errors = [];
      /* const salt = bcrypt.genSaltSync(10);
      this.credentials.password = bcrypt.hashSync(this.credentials.password, salt);
      console.log(`Encoded: ${this.credentials.password}\n`); */

      /* // Grab the options we want to use.
      // These options will take close to, but not more than, 100ms to compute a hash.
      // The first run of getMaxOptions() takes a while (~5s on my laptop) so you should
      // call it at startup, not when the first password hash request comes in.
      // Subsequent calls use a cache.
      var maxOpts;
      var plain = this.credentials.password;

      argon2
        .getMaxOptions()
        .then(function(options) {
          maxOpts = options;
          // Each password should have a secure, unique, salt. The argon2 module provides that.
          return argon2.generateSalt();
        })
        .then(function(salt) {
          // Hashing happens in an asynchronous event using libuv so your system can
          // still process other IO items in the Node.JS queue, such as web requests.
          return argon2.hash(plain, salt, maxOpts);
        })
        .then(function(hash) {
          // This hash is what you should store in your database. Treat it as an opaque string.
          console.log(hash);
          // Verifying the hash against your user's password is simple.
          return argon2.verify(hash, plain);
        })
        .then(function(match) {
          // Does this password match the hash?
          return match;
        }); */

      /* argon2
        .hash({
          pass: this.credentials.password,
          salt: this.salt,
        })
        .then((hash) => {
          console.log(`Encoded: ${hash.encoded}\n` + `Hex: ${hash.hashHex}\n`);

          argon2
            .verify({
              pass: "p@ssw0rd",
              encoded: hash.encoded,
            })
            .then(() => (document.querySelector("pre").innerText += "Verified OK"))
            .catch((e) => console.error("Error: ", e));
        })
        .catch((e) => console.error("Error: ", e)); */

      this.$store
        .dispatch("login", { user: this.credentials.user, password: this.credentials.password })
        .then(() => {
          // redirect User to Dashboard after login
          this.$router.push("/Dashboard");
        })
        .catch((error) => {
          this.loginError = true;
          this.errors.push(error);
          this.error = true;
        });
    },
    /*  watch: {
      loginError: {
        handler(timerCount) {
          if (timerCount > 0) {
            setTimeout(() => {
              this.timerCount--;
            }, 1000);
          }
          this.loginError = false;
        },
        immediate: false, // This ensures the watcher is triggered upon creation
      },
    }, */

    /* callLogin() {
      console.log("called login as " + this.credentials.user + " " + this.credentials.password);
      api
        .getSecured(this.credentials.user, this.credentials.password)
        .then((response) => {
          console.log("Response: '" + response.data + "' with Statuscode " + response.status);
          if (response.status == 200) {
            this.loginSuccess = true;
          }
        })
        .catch((error) => {
          console.log("Error: " + error);
          this.loginError = true;
        });
    }, */
  },
};
</script>

<style scoped>
.login {
  margin-top: 50px;
}

html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
